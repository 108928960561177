<template>
  <modal-template-one ref="modalTemplate" wr-result-modal>
    <button class="close-btn" @click="close">
      <img src="/img/pages/icon/x_button-w.svg" alt="close" />
    </button>
    <WrSectionTitle title="결선 진출팀" />
    <div class="streamer-team">
      <h2>스트리머 2팀</h2>
      <div class="team-box">
        <div>
          <p>Team. 얼불져스</p>
          <ul>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_A_01.jpg" alt="캡틴잭">
              <div>
                <span>캡틴잭</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_A_02.jpg" alt="매드라이프">
              <div>
                <span>매드라이프</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_A_03.jpg" alt="갱맘">
              <div>
                <span>갱맘</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_A_04.jpg" alt="헬리오스">
              <div>
                <span>헬리오스</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_A_05.jpg" alt="플레임">
              <div>
                <span>플레임</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="team-box">
        <div>
          <p>Team. 얍릎젤푸봉</p>
          <ul>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_B_01.jpg" alt="얍얍">
              <div>
                <span>얍얍</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_B_02.jpg" alt="남봉">
              <div>
                <span>남봉</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_B_03.jpg" alt="소우릎">
              <div>
                <span>소우릎</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_B_04.jpg" alt="인간젤리">
              <div>
                <span>인간젤리</span>
              </div>
            </li>
            <li>
              <img src="/img/pages/wariwari-team/TEAM_B_05.jpg" alt="푸린">
              <div>
                <span>푸린</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="main-pass-team">
      <h2>본선통과 14팀</h2>
      <div class="gray-box">
        <ul>
          <li>GLUK Gaming</li>
          <li>AFW</li>
          <li>Break Frame Gaming</li>
          <li>NOGANKING</li>
          <li>3T2J</li>
          <li>New Meta</li>
          <li>LGTM</li>
          <li>KDASP</li>
          <li>ASQ</li>
          <li>Team NC</li>
          <li> NC.</li>
          <li>AI HappyWin</li>
          <li>Say GGs</li>
          <li>Baby is 87</li>
        </ul>
      </div>
    </div>
  </modal-template-one>
</template>

<script>
import ModalTemplateOne from '@/views/components/common/modal/ModalTemplateOne.vue';
import WrSectionTitle from '@/views/components/landing/wariwari/WrSectionTitle.vue';

export default {
  name: 'WariWariResultModal',
  components: { ModalTemplateOne, WrSectionTitle },
  methods: {
    close() {
      this.$refs.modalTemplate.selfClose();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wr-result-modal] { align-items: center; .p(10, 0);
  .modal-con { .rel; .w(100%); .h; .max-w(340); .max-h(100%); overflow-y: auto; .p(20); .pt(48); .bgc(#24262c); .-a(rgba(255, 255, 255, 0.1), 1); .br(20);
    .close-btn { .abs; .rt(15, 15); z-index: 1;}
    [wr-section-title] {
      > div { .block;
        .title { .fs(28, 36.4); .c(#fff); .ls(-2);
          &::before { .wh(18, 25); .mr(8); }
          &::after { .wh(18, 25); .ml(8); }
        }
        > span { transform: scaleX(1); .-t(#a38e40, 1);
          &::before { .w(126); .-t(#a38e40, 4); }
        }
      }
    }
    .streamer-team {
      > h2 { .mt(19.6); .mb(12); .tc; .font-g; .fs(24, 28.8); .ls(-1); .bold; .c(#a38e40); }
      .team-box { .rel; .mb(15);
        &::before { .cnt; .abs; .lt(0, 9); z-index: 1; .wh(100%, 98%); .bgc(rgba(0, 0, 0, 0.2)); }
        > div { .rel; z-index: 2;
          > p { .pl(20); .mb(10); .ml(10); .font-g; .fs(20, 24); .ls(-1); .semi-bold; .c(#fff); .contain('/img/pages/icon/icon-diamond-yellow.svg'); .bg-w(14); .bg-xy(0, 50%); }
          > ul { .tc;
            li { .ib; .w(75.43); .mb(12); .mr(12);
              > img { .wh(100%, 100%); }
              > div { .rel;
                &::before { .cnt; .h(14); .bgc(#a38e40); }
                &::after { .cnt; .-t(#a38e40, 8); .-r(transparent, 8); }
                > span { .abs; .lt(0, 0); z-index: 1; .block; .wh(100%, 100%); .p(5, 0); .tc; .font-g; .fs(12, 12); .c(#fff); .ls(-0.67); }
              }
            }
          }
        }
      }
    }
    .main-pass-team { .mt(24);
      > h2 { .mt(19.6); .mb(12); .tc; .font-g; .fs(24, 28.8); .ls(-1); .bold; .c(#33c6ff); }
      .gray-box { .p(24, 10, 10); .pr(0); .mt(16); .bgc(#363840); .br(4);
        ul { .crop; .max-w(400); .mh-c;
          li { .fl; .w(65%); .mb(12); .pl(16); .pr(0); .fs(14); .c(#fff); .ls(-0.5); .contain('/img/pages/icon/icon-diamond-blue.svg'); .bg-w(11); .bg-xy(0, 50%);
            &:nth-child(even) { .w(35%); }
          }
        }
      }
    }
  }
  @media (@tp-up) {
    .modal-con { .max-w(640); .pt(60);
      .streamer-team {
        > h2 { .mt(42.58); .mb(16); }
        .team-box { .mb(20);
          > div {
            > ul {
              li { .ib; .w(96); .mb(0); .mr(16);
                > div { .rel;
                  &::before { .cnt; .h(18); .bgc(#a38e40); }
                  &::after { .cnt; .-t(#a38e40, 10); .-r(transparent, 10); }
                  > span { .p(7, 0);.fs(14, 14); }
                }
              }
            }
          }
        }
      }
      .main-pass-team { .mt(32);
        .gray-box {
          ul {
            li { .fs(20); .ls(-1); }
          }
        }
      }
    }
  }
}
</style>
