<template>
  <div wr-section-title class="wr-section-title">
    <div>
      <h4 v-que="{animation: 'fadeIn', delay: 0, duration: 1000}" class="title">{{ title }}</h4>
      <span v-que="{delay: 500}"></span>
    </div>
  </div>
</template>

<script>
import que from '@/directives/que';

export default {
  name: 'WrSectionTitle',
  directives: { que },
  props: {
    title: { type: String, default: '' },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wr-section-title] { .tc;
  > div { .ib;
    .title { .fs(28, 36.4px); .c(#1a1d22); .p(0, 16); .ls(-7%);
      &::before, &:after { .cnt; .ib; .wh(22, 30.9); vertical-align: sub; .contain('/img/event/wariwari/landing/Icon/Title_element/Title_shape_gold.svg');}

      &::before { .mr(12); }
      &::after { .ml(12); }
    }
    > span { .block; .mt(12); .-t(#a38e40, 1); .tc; .crop; transform: scaleX(0); transition: transform 0.3s;
      &::before { .cnt; .w(94.4); .mh-c; .-t(#a38e40, 4); .-l(transparent, 3); .-r(transparent, 3); .-box; }
      &.que-done { transform: scaleX(1); }
    }
  }
  @media (@tl-up) {
    > div {
      .title { .fs(52, 67.6); .p(0, 22);
        &:before { .wh(37, 52); .mr(16); }
        &:after { .wh(37, 52); .ml((16);) }
      }
      > span {.-t(#a38e40, 2);
        &::before { .w(156);.-t(#a38e40, 8); .-l(transparent, 5); .-r(transparent, 5); .-box; }
      }
    }
  }
}
</style>
