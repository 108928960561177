<template>
  <div modal-template-one @click.self="selfClose">
    <transition :name="transitionName" @after-leave="close">
      <div class="modal-con" v-show="show">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ModalTemplateOne',
  data() {
    return {
      show: false,
    };
  },
  props: {
    transition: {
      type: String,
      default: 'fade',
    },
  },
  computed: {
    transitionName() {
      if (window.innerWidth < 1024) {
        return 'md-slide';
      }

      return `md-${this.transition}`;
    },
  },
  methods: {
    selfClose() {
      this.show = false;
    },
    close() {
      this.$parent.$emit('close');
    },
  },
  mounted() {
    this.show = true;
    this.$scroll.freeze();
  },
  beforeDestroy() {
    this.$scroll.release();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[modal-template-one] { .fix; .lt(0, 0); .r(0); z-index: 1; .h(100%); .flex; .flex-jc(center); .flex-ai(flex-end); .p(150, 0); .pb(0); .bgc(rgba(0, 0, 0, 0.6));

  .modal-con { .wh(100%, 100%); .mh-c; .bgc(#fff); }

  @media (@tp-up) {
    .flex-jc(center); .flex-ai(center); .p(150, 0);
    .modal-con { .wh(200, 200); }
  }

  /**
  * Transition
  */
  .md-fade-enter-active {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: md-fade;
  }

  .md-fade-leave-active {
    animation-duration: 0.07s;
    animation-fill-mode: both;
    animation-direction: reverse;
    animation-name: md-fade;
  }

  .md-zoom-enter-active {
    animation-duration: 0.21s;
    animation-fill-mode: both;
    animation-name: md-fade-in;
  }

  .md-zoom-leave-active {
    animation-duration: 0.07s;
    animation-fill-mode: both;
    animation-name: md-fade-out;
  }

  .md-slide-enter-active {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-name: md-slide;
  }

  .md-slide-leave-active {
    animation-duration: 0.21s;
    animation-fill-mode: both;
    animation-direction: reverse;
    animation-name: md-slide;
  }

  @keyframes md-fade-in {
    from {
      opacity: 0;
      transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes md-fade-out {
    from { .o(1); }

    to { .o(0); }
  }

  @keyframes md-fade {
    from { .o(0); }

    to { .o(1); }
  }

  @keyframes md-slide {
    from { .o(0); transform: translateY(100%); }

    to { .o(1); transform: translateY(0); }
  }
}
</style>
